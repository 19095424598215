import React from 'react';
import './style.scss';

const Redeemable = () => {
    return (
        <div className='ewRedeemWrap'>
            <div className='engageContainer'>
                <div className='ewRedeemHeader'>
                    <h2>Easy redeemable options</h2>
                </div>
                <div className='ewRedeem'>
                    <div className='ewRedeemImagery'>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/walmart.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/walmart.png'}
                                alt={'walmart'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/adidas.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/adidas.png'}
                                alt={'adidas'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/under-armour.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/under-armour.png'}
                                alt={'under armour'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/groupon.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/groupon.png'}
                                alt={'groupon'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/macy.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/macy.png'}
                                alt={'macy'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/bath-body-works.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/bath-body-works.png'}
                                alt={'bath and body works'}
                            />
                        </span>
                    </div>
                    <div className='ewRedeemImagery'>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/old-navy.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/old-navy.png'}
                                alt={'old navy'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/star-bucks-awork.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/star-bucks-awork.png'}
                                alt={'starbucks'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/dominos-pizza.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/dominos-pizza.png'}
                                alt={'dominos'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/dunkin-donuts.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/dunkin-donuts.png'}
                                alt={'dunkin donuts'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/nike.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/nike.png'}
                                alt={'nike'}
                            />
                        </span>
                        <span>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/redeem/amazon.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/redeem/amazon.png'}
                                alt={'amazon'}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Redeemable;

import React from 'react';
import './style.scss';

const MotivateWorkspace = () => {
    return (
        <div className='motivateWorkspaceWrap'>
            <div className='engageContainer'>
                <div className='motivateWorkspaceHeader'>
                    <h2>Because a happy team means a motivated workplace</h2>
                    <p>
                        You don’t have to be an HR to appreciate your people. We built EngageWith to make recognition
                        and appreciation, a part of your organization’s daily culture.{' '}
                    </p>
                </div>

                <div className='mobIllustration'>
                    <img
                        src={'https://sw-assets.gumlet.io/engagewith/illustration.png'}
                        data-src={'https://sw-assets.gumlet.io/engagewith/illustration.png'}
                        alt='illustration'
                    />
                </div>
                <div className='motivateWorkspaceSection'>
                    <div className='motivateWorkspaceLeft'>
                        <div className='motivateWorkspace'>
                            <div className='motivate'>
                                <img
                                    src={'https://sw-assets.gumlet.io/engagewith/peer.png'}
                                    data-src={'https://sw-assets.gumlet.io/engagewith/peer.png'}
                                    alt='peer'
                                />
                                <h6>Peer-to-peer Appreciation</h6>
                                <p>
                                    See someone doing an awesome job? Kick start the karma of recognition by giving a
                                    Kudos or Shoutout. Remember ‘Karma is a boomerang’.
                                </p>
                            </div>
                            <div className='motivate'>
                                <img
                                    src={'https://sw-assets.gumlet.io/engagewith/social.png'}
                                    data-src={'https://sw-assets.gumlet.io/engagewith/social.png'}
                                    alt='social'
                                />
                                <h6>Social Recognition</h6>
                                <p>
                                    Scale-up the power of Thank you by sharing gratitude publicly. Anyone can appreciate
                                    anyone - from direct reports to managers and C-level executives.{' '}
                                </p>
                            </div>
                        </div>

                        <div className='motivateWorkspace'>
                            <div className='motivate'>
                                <img
                                    src={'https://sw-assets.gumlet.io/engagewith/customizable.png'}
                                    data-src={'https://sw-assets.gumlet.io/engagewith/customizable.png'}
                                    alt='custom'
                                />
                                <h6>Customizable Rewards</h6>
                                <p>One size never fits all. Tailor rewards based on individual preferences.</p>
                            </div>
                            <div className='motivate'>
                                <img
                                    src={'https://sw-assets.gumlet.io/engagewith/dynamic.png'}
                                    data-src={'https://sw-assets.gumlet.io/engagewith/dynamic.png'}
                                    alt='dynamic'
                                />
                                <h6>Dynamic Leaderboards</h6>
                                <p>
                                    Rank individuals based on appreciation sent & received. Keep your team's engagements
                                    ticking and motivations lively.{' '}
                                </p>
                            </div>
                        </div>

                        <div className='motivateWorkspace'>
                            <div className='motivate'>
                                <img
                                    src={'https://sw-assets.gumlet.io/engagewith/happens.png'}
                                    data-src={'https://sw-assets.gumlet.io/engagewith/happens.png'}
                                    alt='happens'
                                />
                                <h6>Happens where work happens</h6>
                                <p>
                                    No need to jump onto another platform to say Thanks. Everything happens right inside
                                    Slack and Microsoft Teams.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='motivateWorkspaceRight'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/illustration.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/illustration.png'}
                            alt='illustration'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MotivateWorkspace;

import React, { useState } from 'react';
import './style.scss';

const Index = () => {
    const tempIndustry = [
        {
            industry: 'Tech',
            isActive: true,
            percentage: 20,
        },
        {
            industry: 'Manufacturing',
            isActive: false,
            percentage: 16,
        },
        {
            industry: 'Finance',
            isActive: false,
            percentage: 15,
        },
        {
            industry: 'Others',
            isActive: false,
            percentage: 17.7,
        },
    ];
    const [state, setState] = useState({
        employeeCount: 100,
        percentage: { percentage: 20, amount: 18180 },
        industry: tempIndustry,
    });

    const handleIndustry = (index) => {
        let temp = [...state.industry];
        let tempPercentage = JSON.parse(JSON.stringify(state.percentage));
        temp.forEach((industry, i) => (i === index ? (industry.isActive = true) : (industry.isActive = false)));
        tempPercentage.percentage = temp[index].percentage;
        tempPercentage.amount = parseFloat(
            (state.employeeCount * (temp[index].percentage / 100) * 3000 * 0.303).toString()
        ).toFixed(0);
        setState({ employeeCount: state.employeeCount, percentage: tempPercentage, industry: temp });
    };

    const handleInputChange = (value) => {
        if ((value >= 0 || value === '') && value < 2001 && !value.includes('.')) {
            let tempPercentage = JSON.parse(JSON.stringify(state.percentage));
            tempPercentage.amount = parseFloat(
                (value * (tempPercentage.percentage / 100) * 3000 * 0.303).toString()
            ).toFixed(0);
            setState({ employeeCount: value, percentage: tempPercentage, industry: state.industry });
        }
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className='appreciationCostWrap'>
            <div className='appreciationCostHeader'>
                <h4>How much can EngageWith help you save? </h4>
            </div>

            <div className='appreciationCostEmployee'>
                <div className='employeeLeft'>
                    <p>Employees</p>
                    <input
                        value={state.employeeCount}
                        onChange={(e) => handleInputChange(e.target.value)}
                        className='pl-2'
                        type='text'
                    />
                </div>

                <div className='employeeRight'>
                    <p>EngageWith saves per year</p>
                    <h3>${state.employeeCount > 1 ? numberWithCommas(state.percentage.amount) : 0}</h3>
                </div>
            </div>

            <div className='employeeIndustry'>
                <p>Industry</p>
                <div className='employeeIndustryInner'>
                    {state.industry.map((industry, i) => (
                        <span
                            onClick={() => handleIndustry(i)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) handleIndustry(i);
                            }}
                            tabIndex={'0'}
                            role={'button'}
                            className={industry.isActive ? `industryActive` : ''}
                        >
                            {industry.industry}
                        </span>
                    ))}
                </div>
            </div>

            <div className='slidecontainer'>
                <div className='sliderViewer'>
                    <input
                        value={state.employeeCount === '' ? 0 : state.employeeCount}
                        onChange={(e) => handleInputChange(e.target.value)}
                        min='0'
                        max='2000'
                        type='range'
                        className='slider'
                        id='myRange'
                    />
                </div>
            </div>

            <div className='employeeSeeDetail'>
                <button
                    onClick={() =>
                        window.open(
                            `/engagewith/details/?value=${state.employeeCount > 1 ? state.employeeCount : 0}`,
                            '_blank'
                        )
                    }
                >
                    See Details
                </button>
            </div>
        </div>
    );
};

export default Index;

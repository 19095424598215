import React from 'react';
import Navbar from '../Navbar';
import './style.scss';
import CTAButtons from '../CTAButtons';
import Slide from 'react-reveal/Slide';
import AppreciationCost from './AppreciationCost';

const Header = () => {
    return (
        <div className='engageWithHeaderWrap'>
            <Navbar />
            <div className='engageContainer'>
                <div className='ewHeaderContentWrap'>
                    <Slide top>
                        <div className='ewHeaderContentLeft'>
                            <h1>Celebrate great work as it happens</h1>
                            <p>
                                Reward and recognize your coworkers by celebrating wins big or small right inside Slack
                                and Microsoft Teams.
                            </p>
                            <CTAButtons />
                        </div>
                    </Slide>
                    <Slide right>
                        <div className='ewHeaderContentRight'>
                            <AppreciationCost />
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default Header;

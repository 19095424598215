import React from 'react';
import './style.scss';

const LittleAppreciation = () => {
    return (
        <div className='littleAppreciationWrap'>
            <div className='engageContainer'>
                <div className='littleAppreciationHeader'>
                    <h2>A little appreciation can indeed go a long way</h2>
                </div>
                <div className='littleAppreciation'>
                    <div className='littleAppreciationDesc'>
                        <h3>52%</h3>
                        <p>Employees believe in instant recognition</p>
                    </div>
                    <div className='littleAppreciationDesc'>
                        <h3>5X</h3>
                        <p>Increase in productivity</p>
                    </div>
                    <div className='littleAppreciationDesc'>
                        <h3>80%</h3>
                        <p>Reduction in employee turnover</p>
                    </div>
                    <div className='littleAppreciationDesc'>
                        <h3>10X</h3>
                        <p>Team morale </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LittleAppreciation;

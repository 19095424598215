import React from 'react';
import './style.scss';
import { Carousel } from 'react-bootstrap';

const People = () => {
    return (
        <div className='engageContainer'>
            <div className='ewPeopleWrapHeader'>
                <h2>We believe people come first</h2>
            </div>
            <div className='ewPeopleWrap'>
                <div className='ewPeopleInner'>
                    <div className='ewPeople'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/happy-birthday.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/happy-birthday.png'}
                            placeholder={'tracedSVG'}
                            alt='happy birthday'
                        />
                        <h5>
                            Goodbye excuses.
                            <br />
                            Hello Birthday Bots!{' '}
                        </h5>
                        <p>
                            Get notified on your peer’s special day. Nothing annoying, just a gentle reminder so you can
                            ensure they get a proper b-day bash.
                        </p>
                    </div>
                    <div className='ewPeople'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/celebrate.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/celebrate.png'}
                            placeholder={'tracedSVG'}
                            alt='celebrate'
                        />
                        <h5>
                            Celebrate Work
                            <br />
                            Anniversaries
                        </h5>
                        <p>
                            Honor milestones and celebrate loyalty. Reward co-workers with a memorable Work Anniversary.
                        </p>
                    </div>
                    <div className='ewPeople'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/incentivize.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/incentivize.png'}
                            placeholder={'tracedSVG'}
                            alt='incentivize'
                        />
                        <h5>
                            Incentivize Recognition
                            <br />
                            on preferred platforms
                        </h5>
                        <p>
                            Recognition can lift culture. But giving the employees a chance to redeem points where they
                            want? That’s a game-changer!
                        </p>
                    </div>
                </div>
            </div>

            <Carousel className='ewPeopleSlider'>
                <Carousel.Item>
                    <div className='ewPeople'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/happy-birthday.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/happy-birthday.png'}
                            placeholder={'tracedSVG'}
                            alt='happy birthday'
                        />
                        <h5>
                            Goodbye excuses.
                            <br />
                            Hello Birthday Bots!{' '}
                        </h5>
                        <p>
                            Get notified on your peer’s special day. Nothing annoying, just a gentle reminder so you can
                            ensure they get a proper b-day bash.
                        </p>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='ewPeople'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/celebrate.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/celebrate.png'}
                            placeholder={'tracedSVG'}
                            alt='celebrate'
                        />
                        <h5>
                            Celebrate Work
                            <br />
                            Anniversaries
                        </h5>
                        <p>
                            Honor milestones and celebrate loyalty. Reward co-workers with a memorable Work Anniversary.
                        </p>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='ewPeople'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/incentivize.png'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/incentivize.png'}
                            placeholder={'tracedSVG'}
                            alt='incentivize'
                        />
                        <h5>
                            Incentivize Recognition
                            <br />
                            on preferred platforms
                        </h5>
                        <p>
                            Recognition can lift culture. But giving the employees a chance to redeem points where they
                            want? That’s a game-changer!
                        </p>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default People;
